import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { WorkFlow, WorkflowInteraction } from '@eva-model/workflow';
import { WorkflowService } from '@eva-services/workflow/workflow.service';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { IdMapDialogComponent } from '@eva-ui/id-map-dialog/id-map-dialog.component';
import { FormViewerModel } from '@eva-model/formViewerModel';
// eslint-disable-next-line max-len
import { IdMapWorkflowInteractionDialogComponent } from '@eva-ui/id-map-workflow-interaction-dialog/id-map-workflow-interaction-dialog.component';
import { GroupSelectDialogComponent } from '@eva-ui/group-select-dialog/group-select-dialog.component';
import { EntityPickerDialogComponent } from '@eva-ui/form-builder/entity-picker-dialog/entity-picker-dialog.component';
// eslint-disable-next-line max-len
import { IdMapWorkflowInteractionMapDialogComponent } from '@eva-ui/id-map-workflow-interaction-map-dialog/id-map-workflow-interaction-map-dialog.component';
import { IdMapInteractionChangesComponent } from '@eva-ui/id-map-interaction-changes/id-map-interaction-changes.component';
import { IdMapEntityGroupMapComponent } from '@eva-ui/id-map-entity-group-map/id-map-entity-group-map.component';
import { saveAs } from 'file-saver';
import { IdMapIdChangesComponent } from '@eva-ui/id-map-id-changes/id-map-id-changes.component';
import {
  IdMapWorkflowInteractionElementDialogComponent
} from '@eva-ui/id-map-workflow-interaction-element-dialog/id-map-workflow-interaction-element-dialog.component';
import {
  IdMapPair, Pair, ExportImportWorkflow, IdMapWorkflowInteractionDialogResult, GroupSelectDialogResult,
  IdMapWorkflowInteractionMapDialogResult, IdMapEntityGroupMapResult
} from '@eva-model/idMap';
import * as _ from 'lodash';
import { Subject, BehaviorSubject, SubscriptionLike as ISubscription } from 'rxjs';
import { LoggingService } from '@eva-core/logging.service';
import { DynamicInteractionsService } from '@eva-services/dynamicforms/dynamic-forms.service';
import { entityType, entityPickType } from '../../model/entity';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { WorkflowBuilderComponent } from '@eva-ui/workflow-builder/workflow-builder.component';
import { Guid } from '@eva-core/GUID/guid';
import { ProjectSettings } from 'app/settings/globalvariables';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';
import { IdMapperService } from '@eva-services/id-mapper/id-mapper.service';
import { SigningService } from '@eva-core/signing.service';
import { take } from 'rxjs/operators';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';
import { environment } from '@environments/environment';
import { IdMapping, IDMappingResponse, IdMappingType } from '@eva-model/id-mapping/id-mapping';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-workflow-import-export',
  templateUrl: './workflow-import-export.component.html',
  styleUrls: ['./workflow-import-export.component.scss']
})
export class WorkflowImportExportComponent {
  private idMapperGetUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'getMappingId'; // the id mapper for the settings
  idList: any[] = [];                                                     // This is the list for storing all the mapped id's for
  uploadedJSONSubscription: Subject<any> = null;                          // This is the subscription for importing a file for workflow
  importedWorkflowGroupName = '';                                         // Group name of the imported interaction
  newWorkflowId: string;                                                  // Id of the new workflow with 'create new' option on import
  @Input() interactionsByGroups: any = {};                                // List of all the interactions belonging to the groups

  @ViewChild('uploadJSONFile') uploadFileInput: ElementRef;               // reference of import input html element
  @Input() isWaitingForGroupInteractions: boolean;                        // whether waiting for group interactions or not
  @Input() waitForGroupInteractionsMessage: string;                       // message displayed when isWaitingForGroupInteractions is true
  @Input() workflow: WorkFlow;                                            // WorkFlow object storing all the data regarding workflow
  @Input() isWaiting: boolean;                                            // whether waiting for workflow submission or not
  @Input() waitMessage: string;                                           // message displayed when isWaiting is true
  @Input() buttonType: string;                                            // Type of the button to show - "Upload"/"Download"
  @Input() submitWorkFlowToBlock: Function;                               // This function fetches the interactions from database
                                                                          // and sets up the object that gets submitted to block
  @Input() workflowBuilderComponent: WorkflowBuilderComponent;            // Workflow Builder component reference
  @Input() workflowSubmittedToBlockSubscription: Subject<boolean>;        // This is the subscription for when the imported workflow gets
                                                                          // submitted to block
  @Input() openGeneralDialog: Function;                                   // This function opens a GeneralDialogComponent Dialog
  @Input() getInteractionsByUserGroups: Function;                         // This function calls interaction fetcher method for all groups
  @Input() generalDialogChangeSubscription: ISubscription;                // Subscription to the General Dialog changes
  @Output() isWaitingChange: EventEmitter<boolean>
  = new EventEmitter<boolean>();                                          // Emitter for isWaiting value changes
  @Output() waitMessageChange: EventEmitter<string>
  = new EventEmitter<string>();                                           // Emitter for waitMessage value changes
  @Output() waitForGroupInteractionsMessageChange: EventEmitter<string>
  = new EventEmitter<string>();                                           // Emitter for waitForGroupInteractionMessage changes
  @Output() isWaitingForGroupInteractionsChange: EventEmitter<boolean>
  = new EventEmitter<boolean>();                                          // Emitter for isWaitingForGroupInteractions changes

  constructor(private workflowService: WorkflowService,
    private evaGlobalService: EvaGlobalService,
    private loggingService: LoggingService,
    private interactionService: DynamicInteractionsService,
    private dialog: MatDialog,
    private formBuilderService: FormBuilderService,
    private _http: HttpClient,
    private generalDialogService: GeneralDialogService,
    private idMapperService: IdMapperService,
    public signingService: SigningService) { }

  /**
   * This function downloads the selected workflow as .json file
   *
   * @param workflow Workflow being exported as .json file
   */
  downloadJSON(): void {
    const workflowId = this.workflow.id;
    const workflowVersion = this.workflow['version'];

    this.isWaiting = true;
    this.isWaitingChange.emit(this.isWaiting);
    this.waitMessage = 'Loading the workflow for export';
    this.waitMessageChange.emit(this.waitMessage);
    this.workflowService.fetchWorkflowsByIdAndVer(workflowId, Number(workflowVersion))
      .subscribe(
        (workflowData) => {
          const workflowMapperObject = this.workflowService.workflowObjectMapper(workflowData);
          let environmentName = '';
          const url = window.location.href;

          // set the environment based on url
          if (url.includes('dev.atbeva.com') || url.includes('localhost')) {
            environmentName = 'Development';
          } else if (url.includes('staging.atbeva.com')) {
            environmentName = 'Staging';
          } else if (url.includes('test.atbeva.com')) {
            environmentName = 'Test';
          } else if (url.includes('atbeva.com')) {
            environmentName = 'Production';
          }

          // create a json object and save it as string in the file to be downloaded
          const workflowJSON = JSON.stringify(<ExportImportWorkflow>{
            workflowVersion: workflowVersion,
            workflowMapperObject: workflowMapperObject,
            environment: environmentName,
            groupName: this.evaGlobalService.getGroupNameByPublicKey(workflowMapperObject.groupPublicKey)
          });
          const blob = new Blob([workflowJSON], { type: 'text/json' });
          saveAs(blob, this.workflow.name + '-workflow' + '.json');
        },
        (err) => {
          console.log(err);
          this.isWaiting = false;
          this.isWaitingChange.emit(this.isWaiting);
          this.waitMessage = '';
          this.waitMessageChange.emit(this.waitMessage);
        },
        () => {
          this.isWaiting = false;
          this.isWaitingChange.emit(this.isWaiting);
          this.waitMessage = '';
          this.waitMessageChange.emit(this.waitMessage);
        }
      );
  }

  /**
   * This function initiates workflow import
   */
  uploadJSON(): void {
    try {
      this.idList = [];
      this.idList.length = 0;
      const inputElement = this.uploadFileInput.nativeElement as HTMLElement;
      inputElement.click();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * This function imports the workflow from .json file
   *
   * @param event HTMLChangeEvent
   */
  uploadFile(event: any): void {
    const files = event.target.files;                             // list of files being imported

    // if file is selected and filetype is json
    if (files.length > 0) {
      if (files[0].type === 'application/json') {
        this.uploadedJSONSubscription = new Subject<any>();       // subscription for reading data from json file once ready
        const fileReader = new FileReader();                      // async file reader js library
        fileReader.readAsText(files[0], "UTF-8");                 // specify file to be opened as text
        fileReader.onload = () => {                               // this runs once file is ready
          try {
            const jsonData = JSON.parse(fileReader.result as string);

            // if json object is not a valid workflow object, notify user
            if (!jsonData['workflowMapperObject']) {
              this.loggingService.logMessage('Please upload a workflow file', false, 'error');
              event.target.value = '';
              return;
            }
            // update subscription with json file data
            this.uploadedJSONSubscription.next(jsonData);
          } catch (error) {
            this.uploadedJSONSubscription.unsubscribe();
            console.error(error);
          }
        };
        fileReader.onerror = (error) => {                         // this runs if opening file fails
          this.loggingService.logMessage(error.type, false, 'error');
        };
        // open user choice dialog once json file is opened
        this.uploadedJSONSubscription.subscribe((jsonData: ExportImportWorkflow) => {
          // open dialog box for imported workflow
          if (jsonData) {
            this.openImportActionDialog(jsonData);
          }
          this.uploadedJSONSubscription.unsubscribe();
        });
      } else {
        this.loggingService.logMessage('Please upload a valid JSON file', false, 'error');
      }
      event.target.value = '';
    }
  }

  /**
   * This function updates workflow interactions with paired interactions
   * and updates group and interaction destinations
   *
   * @param workflow Workflow object being updated
   * @param dialogResultData {pairs: [], newInteractions: []}
   * @param groupPairs array containing paired group destinations
   */
  private updateImportedWorkflowObject(workflow: WorkFlow, dialogResultData: IdMapWorkflowInteractionMapDialogResult,
    groupPairs: Pair[]): void {
    workflow.interactions.forEach(interaction => {
      // update new interaction version with old interaction version
      dialogResultData.pairs.forEach(pair => {
        if (pair.new.interactionId === interaction.interactionId) {
          // updating WorkflowInteraction object
          interaction.interactionVersion = pair.old.version;
          interaction.interactionGroupPublicKey = pair.old.groupPublicKey;
          interaction.interactionName = pair.old.name;
          // Updating interaction object inside WorkflowInteraction
          interaction.interaction.version = pair.old.version;
          interaction.interaction.groupPublicKey = pair.old.groupPublicKey;
          interaction.interaction.name = pair.old.name;
        }
      });
      interaction.conditions.forEach(interactionCondition => {
        // update new group and interaction destinations with old one
        interactionCondition.trueDestination.forEach(destination => {
          this.updateDestinations(destination, groupPairs, dialogResultData);
        });
        interactionCondition.falseDestination.forEach(destination => {
          this.updateDestinations(destination, groupPairs, dialogResultData);
        });
      });
    });
  }

  /**
   * This function updates the old group and interactions with new one for imported workflow
   *
   * @param destination Destination object
   * @param groupPairs array containing paired group destinations
   * @param dialogResultData {pairs: [], newInteractions: []}
   */
  private updateDestinations(destination: any, groupPairs: Pair[], dialogResultData: IdMapWorkflowInteractionMapDialogResult): void {
    if (destination.type === 'group') {
      groupPairs.forEach(pair => {
        if (pair.new.group.publicKey === destination.publicKey) {
          destination.publicKey = pair.old.groupPublicKey;
          destination.name = pair.old.groupName;
        }
      });
    } else if (destination.type === 'interaction') {
      dialogResultData.pairs.forEach(pair => {
        if (pair.new.interactionId === destination.id) {
          groupPairs.forEach(groupPair => {
            if (groupPair.new.group.publicKey === destination.groupPublicKey) {
              destination.groupPublicKey = pair.old.groupPublicKey;
            }
          });
          destination.version = pair.old.version;
          destination.name = pair.old.name;
        }
      });
    }
  }

  /**
   * This function generates interactionPairs as WorkflowInteraction objects
   *
   * @param dialogResultData {pairs: [], newInteractions: []}
   * @param interactionPairs Pairs of matched interactions
   * @param subscription subscription for announcing once interaction pairs are ready
   */
  private async updateInteractionPairsBeforeNewIds(dialogResultData: IdMapWorkflowInteractionMapDialogResult, interactionPairs: Pair[],
    subscription: BehaviorSubject<any>, currentWorkflowId: string, newWorkflowId: string): Promise<any> {
    let existingMappings: IdMapping = {};

    try {
      // fetch the mapped id's if exists
      const docId = newWorkflowId + '_' + currentWorkflowId;
      const idMappingRequest = {
        type: IdMappingType.ELEMENT,
        docId: docId
      };
      const documentData = await this._http.post<IDMappingResponse>(this.idMapperGetUrl, idMappingRequest).pipe(
        take(1)
      ).toPromise();
      // set the existing mappings
      if (documentData.idMapping) {
        existingMappings = documentData.idMapping;
      }

    } catch (err) {
      console.log(err);
    }

    dialogResultData.pairs.forEach(pair => {
      let interaction = pair.old;
      this.interactionService.fetchInteractionsByIdAndVer(interaction.id, interaction.version)
        .subscribe(fetchedInteraction => {
          interaction = this.interactionService.interactionObjectMapper(fetchedInteraction);
          interactionPairs.push({
            new: pair.new,
            old: new WorkflowInteraction(interaction.id, interaction.name, interaction.version,
              interaction.groupPublicKey, interaction)
          });
          if (interactionPairs.length === dialogResultData.pairs.length) {
            subscription.next(existingMappings);  // change
          }
        });
    });

    return existingMappings;
  }

  /**
   * This function updates the interaction pairs after new id's have been assigned to the imported workflow
   *
   * @param interactionPairs Pairs of matched interactions
   * @param workflow Workflow object
   * @param submitToBlock Whether to submit the interactions to block or not
   * @param elementPairs Pairs of matched form elements
   */
  private async updateInteractionPairsAfterNewIds(interactionPairs: Pair[], workflow: WorkFlow, submitToBlock: boolean,
    elementPairs: Pair[]): Promise<void> {
    if (submitToBlock) {
      // assign the mapped element id's after new id's have been generated
      for (const pair of elementPairs) {
        workflow.interactions.forEach((interaction: WorkflowInteraction) => {
          interaction.interaction.FormScreens.forEach((screen: any) => {
            screen.FormElements.forEach((element: any) => {
              if (pair.new.id && element.id === pair.new.id) {
                element.id = pair.old.id;
                element.name = pair.old.id;
              }
            });
          });
        });
      }
    }
    // assign the mapped interaction id's for destinations after new id's have been generated
    for (const pair of interactionPairs) {
      workflow.interactions.forEach((interaction: WorkflowInteraction) => {
        interaction.conditions.forEach(condition => {
          condition.trueDestination.forEach(destination => {
            if (destination.type === 'interaction') {
              if (destination.id === pair.new.interactionId) {
                destination.id = pair.old.interactionId;
                // destination.name = pair.old.interactionName;
                destination.version = Number(pair.old.interactionVersion);
              }
            }
          });
          condition.falseDestination.forEach(destination => {
            if (destination.type === 'interaction') {
              if (destination.id === pair.new.interactionId) {
                destination.id = pair.old.interactionId;
                // destination.name = pair.old.interactionName;
                destination.version = Number(pair.old.interactionVersion);
              }
            }
          });
        });
      });
      // update new WorkflowInteraction data with old WorkflowInteraction data
      const oldWorkflowInteractionKeys = Object.keys(pair.old);
      oldWorkflowInteractionKeys.forEach(key => {
        const value = pair.old[key];
        if (typeof value !== 'object') {
          if (key !== 'interactionName') {
            // console.log(`${key}: ${pair.new[key]} [changed to] ${value}`)
            pair.new[key] = value;
          }
        }
      });

      // update new WorkflowInteraction.interaction data with old WorkflowInteraction.interaction data
      const oldInteractionKeys = Object.keys(pair.old.interaction);
      oldInteractionKeys.forEach(key => {
        const value = pair.old.interaction[key];
        if (typeof value !== 'object') {
          if (key !== 'name') {
            // console.log(`${key}: ${pair.new.interaction[key]} [changed to] ${value}`)
            pair.new.interaction[key] = value;
          }
        }
      });

      // assign new version timestamp to the new interaction version
      pair.new.interaction['timestamp'] = Date.now();
      pair.new.interaction['version'] = pair.new.interaction['timestamp'];
      workflow.interactions.forEach((interaction: WorkflowInteraction) => {
        interaction.conditions.forEach(condition => {
          condition.trueDestination.forEach(destination => {
            if (destination.type === 'interaction') {
              if (destination.id === pair.new.interactionId) {
                destination.version = Number(pair.new.interaction['timestamp']);
              }
            }
          });
          condition.falseDestination.forEach(destination => {
            if (destination.type === 'interaction') {
              if (destination.id === pair.new.interactionId) {
                destination.version = Number(pair.new.interaction['timestamp']);
              }
            }
          });
        });
      });
      // delete temporary properties from the object
      delete pair.new.selectedAction;
      delete pair.new.selected;
      if (submitToBlock) {
        this.waitMessage = 'Submitting ' + pair.new.interactionName + ' interaction to block';
        this.waitMessageChange.emit(this.waitMessage);
        try {
          // submit new interaction version to block
          await this.submitFormToBlock(pair.new);
        } catch (error) {
          console.error(error);
          return Promise.reject(error);
        }
      }
      // update workflowInteraction in imported workflow object with old timestamp and new version
      pair.new.interaction['timestamp'] = pair.old.interaction['timestamp'];
      pair.new.interactionVersion = pair.new.interaction['version'];
    }
  }

  /**
   * This function updates new Interactions being imported to new environment after new id's have been assigned
   *
   * @param newInteractions New Interactions being imported
   * @param workflow New workflow being imported
   * @param submitToBlock Whether to submit interactions to block or not
   */
  private async updateNewInteractionsAfterNewIds(newInteractions: WorkflowInteraction[], workflow: WorkFlow,
    submitToBlock: boolean): Promise<void> {
    for (const interaction of newInteractions) {
      // delete temporary properties from the object
      delete interaction['selectedAction'];
      delete interaction['groupChanged'];
      delete interaction['selected'];
      // assign new version timestamp to the new interaction version
      interaction.interaction['timestamp'] = Date.now();
      interaction.interaction['version'] = interaction.interaction['timestamp'];
      workflow.interactions.forEach(workflowInteraction => {
        workflowInteraction.conditions.forEach(condition => {
          condition.trueDestination.forEach(destination => {
            if (destination.type === 'interaction') {
              if (destination.id === interaction.interactionId) {
                destination.version = Number(interaction.interaction['timestamp']);
              }
            }
          });
          condition.falseDestination.forEach(destination => {
            if (destination.type === 'interaction') {
              if (destination.id === interaction.interactionId) {
                destination.version = Number(interaction.interaction['timestamp']);
              }
            }
          });
        });
      });

      if (submitToBlock) {
        this.waitMessage = 'Submitting ' + interaction.interactionName + ' interaction to block';
        this.waitMessageChange.emit(this.waitMessage);
        try {
          // submit new interaction version to block
          await this.submitFormToBlock(interaction);
        } catch (error) {
          console.error(error);
          return Promise.reject(error);
        }
      }
      // update workflowInteraction in imported workflow object with new timestamp version
      interaction.interactionVersion = interaction.interaction['timestamp'];
    }
  }

  /**
   * This function is called when user chooses to create a new workflow from imported one
   * and opens a dialog box for user to choose the action
   *
   * @param jsonData Workflow data from imported .json file
   * @param userAction Action chosen by the user - 'create' or 'map'
   */
  private openCreateNewWorkflowDialog(jsonData: ExportImportWorkflow, userAction: string): void {
    this.openGroupSelectDialog(jsonData, userAction);
  }

  /**
   * This function opens a dialog box of type GroupSelectDialogComponent
   *
   * @param jsonData Workflow data from imported .json file
   * @param userAction Action chosen by the user - 'create' or 'map'
   */
  private openGroupSelectDialog(jsonData: ExportImportWorkflow, userAction: string): void {
    const groupSelectDialogReference = this.dialog.open(GroupSelectDialogComponent, {
      data: entityType.workflow,
      disableClose: true
    });
    groupSelectDialogReference.afterClosed().subscribe(async (userSelection: GroupSelectDialogResult) => {
      if (userSelection) {
        jsonData['workflowMapperObject'].groupPublicKey = userSelection.groupPublicKey;
        this.openIdMapWorkflowInteractionDialog(jsonData, userAction);
      }
    });
  }

  /**
   * This function opens a dialog box of type IdMapWorkflowInteractionDialogComponent
   *
   * @param jsonData Workflow data from imported .json file
   * @param userAction Action chosen by the user - 'create' or 'map'
   * @param callbackData Callback data received from entity picker component for map existing workflow option
   */
  private openIdMapWorkflowInteractionDialog(jsonData: ExportImportWorkflow, userAction: string, callbackData?: any): void {
    if (userAction === 'create') {
      // if user selects create new workflow option, open the IdMapWorkflowInteractionDialogComponent dialog box
      const mapDialogData: FormViewerModel = new FormViewerModel(undefined, undefined, 'Ok', 'Cancel',
        { newWorkflow: jsonData['workflowMapperObject'], environment: jsonData.environment });
      const idMapWorkflowInteractionReference = this.dialog.open(IdMapWorkflowInteractionDialogComponent, {
        data: mapDialogData,
        disableClose: true
      });
      idMapWorkflowInteractionReference.afterClosed().subscribe((dialogResult: IdMapWorkflowInteractionDialogResult) => {
        if (dialogResult) {
          this.openIdMapWorkflowInteractionMapDialog(jsonData, dialogResult, userAction);
        }
      });
    } else if (userAction === 'map') {
      // if user selects map existing workflow option, fetch the to be mapped workflow and
      // open the IdMapWorkflowInteractionDialogComponent dialog box
      this.isWaiting = true;
      this.isWaitingChange.emit(this.isWaiting);
      this.waitMessage = 'Fetching the selected workflow';
      this.waitMessageChange.emit(this.waitMessage);
      this.workflowService.fetchWorkflowsByIdAndVer(callbackData['generalDialogOnChange'].entityId,
        callbackData['generalDialogOnChange'].entityVersion).subscribe(async workflowData => {
          const workflow = this.workflowService.workflowObjectMapper(workflowData);
          let existingMappings: IdMapping = {};

          try {
            // fetch the mapped id's if exists
            const docId = jsonData['workflowMapperObject'].id + '_' + workflow.id;
            const idMappingRequest = {
              type: IdMappingType.INTERACTION,
              docId: docId
            };
            const documentData = await this._http.post<IDMappingResponse>(this.idMapperGetUrl, idMappingRequest).pipe(
              take(1)
            ).toPromise();
            // set the existing mappings
            if (documentData.idMapping) {
              existingMappings = documentData.idMapping;
            }

          } catch (err) {
            console.log(err);
          }

          this.isWaiting = false;
          this.isWaitingChange.emit(this.isWaiting);
          this.waitMessage = '';
          this.waitMessageChange.emit(this.waitMessage);

          const mapDialogData: FormViewerModel = new FormViewerModel(undefined, undefined, 'Ok', 'Cancel',
            {
              oldWorkflow: workflow,
              newWorkflow: jsonData['workflowMapperObject'],
              existingMappings: existingMappings,
              environment: jsonData.environment
            });

          const idMapWorkflowInteractionReference = this.dialog.open(IdMapWorkflowInteractionDialogComponent, {
            data: mapDialogData,
            disableClose: true
          });
          idMapWorkflowInteractionReference.afterClosed().subscribe((dialogResult: IdMapWorkflowInteractionDialogResult) => {
            if (dialogResult) {
              this.openIdMapWorkflowInteractionMapDialog(jsonData, dialogResult, userAction, workflow);
            }
          });
        });
    }
  }

  /**
   * This function opens a dialog box of type IdMapWorkflowInteractionMapDialogComponent
   *
   * @param jsonData Workflow data from imported .json file
   * @param dialogResult Result data from IdMapWorkflowInteractionDialogComponent dialog
   * @param userAction Action chosen by the user - 'create' or 'map'
   * @param workflow Workflow object selected by user for mapping with imported workflow
   */
  private openIdMapWorkflowInteractionMapDialog(jsonData: ExportImportWorkflow, dialogResult: IdMapWorkflowInteractionDialogResult,
    userAction: string, workflow?: WorkFlow): void {
    const flag = dialogResult['map'].length === 0 && dialogResult['new'].length === 0 ? false : true;
    // if all the workflow interactions have been mapped to the mapped workflow, do not open IdMapWorkflowInteractionMapDialogComponent
    // dialog box else do it
    if (flag) {
      const idMapWorkflowInteractionMapReference = this.dialog.open(IdMapWorkflowInteractionMapDialogComponent, {
        data: {
          mapInteractions: dialogResult['map'],
          newInteractions: dialogResult['new'],
          userGroups: JSON.parse(JSON.stringify(this.evaGlobalService.userGroups))
        },
        disableClose: true
      });
      idMapWorkflowInteractionMapReference.afterClosed().subscribe((dialogResultData: IdMapWorkflowInteractionMapDialogResult) => {
        if (dialogResultData) {
          this.openIdMapGroupMapDialog(jsonData, dialogResultData, userAction, workflow, dialogResult);
        }
      });
    } else {
      this.openIdMapGroupMapDialog(jsonData, { newInteractions: [], pairs: [] }, userAction, workflow, dialogResult);
    }
  }

  /**
   * This function opens a dialog box of type IdMapEntityGroupMapComponent
   *
   * @param jsonData Workflow data from imported .json file
   * @param dialogResultData Result data from IdMapWorkflowInteractionMapDialogComponent dialog
   * @param userAction Action chosen by the user - 'create' or 'map'
   * @param workflow Workflow object selected by user for mapping with imported workflow
   * @param dialogResult Result data from IdMapWorkflowInteractionDialogComponent dialog
   */
  private openIdMapGroupMapDialog(jsonData: ExportImportWorkflow, dialogResultData: IdMapWorkflowInteractionMapDialogResult,
    userAction: string, workflow?: WorkFlow, dialogResult?: IdMapWorkflowInteractionDialogResult): void {
    const newWorkflow: WorkFlow = jsonData['workflowMapperObject'];
    const newGroups = [];
    // get list of all the group destinations
    newWorkflow.interactions.forEach(interaction => {
      interaction.conditions.forEach(condition => {
        condition.trueDestination.forEach(dest => {
          if (dest.type === 'group') {
            newGroups.push({
              group: dest,
              interactionName: interaction.interactionName,
              conditionName: condition.name
            });
          }
        });
        condition.falseDestination.forEach(dest => {
          if (dest.type === 'group') {
            newGroups.push({
              group: dest,
              interactionName: interaction.interactionName,
              conditionName: condition.name
            });
          }
        });
      });
    });
    // if group destination exists, open IdMapEntityGroupMapComponent dialog else don't open it
    if (newGroups.length !== 0) {
      const idMapGroupMapDialogReference = this.dialog.open(IdMapEntityGroupMapComponent, {
        data: {
          newGroups: newGroups,
          userGroups: JSON.parse(JSON.stringify(this.evaGlobalService.userGroups))
        },
        disableClose: true
      });
      idMapGroupMapDialogReference.afterClosed().subscribe((resultData: IdMapEntityGroupMapResult) => {
        if (resultData) {
          this.openIdMapInteractionChangesDialog(jsonData, dialogResultData, resultData, userAction, workflow, dialogResult);
        }
      });
    } else {
      this.openIdMapInteractionChangesDialog(jsonData, dialogResultData, { pairs: [] }, userAction, workflow, dialogResult);
    }
  }

  /**
   * This function opens a dialog box of type IdMapEntityGroupMapComponent
   *
   * @param jsonData Workflow data from imported .json file
   * @param dialogResultData Result data from IdMapWorkflowInteractionMapDialogComponent dialog
   * @param resultData Result data from IdMapEntityGroupMapComponent dialog
   * @param userAction Action chosen by the user - 'create' or 'map'
   * @param oldWorkflow Workflow object selected by user for mapping with imported workflow
   * @param dialogResult Result data from IdMapWorkflowInteractionDialogComponent dialog
   */
  private async openIdMapInteractionChangesDialog(jsonData: ExportImportWorkflow, dialogResultData: IdMapWorkflowInteractionMapDialogResult,
    resultData: IdMapEntityGroupMapResult, userAction: string, oldWorkflow?: WorkFlow,
    dialogResult?: IdMapWorkflowInteractionDialogResult): Promise<void> {
    if (userAction === 'create') {
      // if user selects create new workflow, open IdMapWorkflowInteractionElementDialogComponent
      // dialog for element mapping if any interactions mapped
      const groupPairs = resultData.pairs;
      const workflow = JSON.parse(JSON.stringify(jsonData['workflowMapperObject']));
      this.updateImportedWorkflowObject(workflow, dialogResultData, groupPairs);

      this.isWaiting = true;
      this.isWaitingChange.emit(this.isWaiting);
      this.waitMessage = 'Updating workflow id\'s';
      this.waitMessageChange.emit(this.waitMessage);
      const subscription = new BehaviorSubject<any>(null);
      const newInteractions = dialogResultData['newInteractions'];
      const interactionPairs: Pair[] = [];
      this.newWorkflowId = Guid.newGuid().toString();

      const mappings = await this.updateInteractionPairsBeforeNewIds(dialogResultData, interactionPairs, subscription,
        this.newWorkflowId, jsonData['workflowMapperObject'].id);
      if (dialogResultData.pairs.length === 0) {
        subscription.next(mappings);
      }

      subscription.subscribe(async existingMappings => {
        if (existingMappings) {
          const importedWorkflowName = jsonData['workflowMapperObject'].name;
          this.openIdMapWorkflowInteractionElementDialog(jsonData, workflow, interactionPairs,
            newInteractions, subscription, userAction, existingMappings, null, null, null, null, importedWorkflowName);
        }
      });
    } else if (userAction === 'map') {
      // if user selects map existing workflow, open IdMapWorkflowInteractionElementDialogComponent
      // dialog for element mapping if any interactions mapped
      const groupPairs = resultData.pairs;
      const subscription = new BehaviorSubject<any>(null);
      let newInteractions = dialogResultData['newInteractions'];
      const interactionPairs: Pair[] = [];
      this.isWaiting = true;
      this.isWaitingChange.emit(this.isWaiting);
      this.waitMessage = 'Fetching mapped interactions';
      this.waitMessageChange.emit(this.waitMessage);

      const mappings = await this.updateInteractionPairsBeforeNewIds(dialogResultData, interactionPairs, subscription,
        oldWorkflow.id , jsonData['workflowMapperObject'].id);

      if (dialogResultData.pairs.length === 0) {
        subscription.next(mappings);
      }

      subscription.subscribe(async existingMappings => {
        if (existingMappings) {
          this.isWaiting = false;
          this.isWaitingChange.emit(this.isWaiting);
          this.waitMessage = '';
          this.waitMessageChange.emit(this.waitMessage);
          let pairs: Pair[] = dialogResult['pairs'].concat(interactionPairs);
          pairs.forEach(pair => {
            pair.old["id"] = pair.old["interactionId"];
            pair.new["id"] = pair.new["interactionId"];
          });

          const importedWorkflowName = jsonData['workflowMapperObject'].name;
          jsonData['workflowMapperObject'].name = oldWorkflow.name;
          // update newInteractions array with workflowInteraction object references
          newInteractions = newInteractions.map(interaction => {
            const workflowInteraction = jsonData['workflowMapperObject'].interactions.find((interactionObject: WorkflowInteraction) =>
              interactionObject.interactionId === interaction.interactionId);
            interaction = workflowInteraction;
            return workflowInteraction;
          });

          // update pairs array with workflowInteraction object references
          pairs = pairs.map(pair => {
            const workflowInteraction = jsonData['workflowMapperObject'].interactions.find((interactionObject: WorkflowInteraction) =>
              interactionObject.interactionId === pair.new.interactionId);
            pair.new = workflowInteraction;
            return pair;
          });

          this.openIdMapWorkflowInteractionElementDialog(jsonData, jsonData['workflowMapperObject'], interactionPairs,
            newInteractions, subscription, userAction, existingMappings, oldWorkflow, pairs, dialogResultData, groupPairs,
            importedWorkflowName);
        }
      });
    }
  }

  /**
   * This function submits new versions of interactions and workflow to the block
   *
   * @param jsonData Workflow data from imported .json file
   * @param workflow Imported workflow object being submitted to block
   * @param interactionPairs Pairs of matched interactions
   * @param newInteractions New Interactions being imported
   * @param subscription subscription for announcing once interaction pairs are ready
   * @param userAction Action chosen by the user - 'create' or 'map'
   * @param elementPairs Pairs of matched form elements
   * @param oldWorkflow Workflow object selected by user for mapping with imported workflow
   * @param pairs Mapped Workflow Interactions
   */
  private async updateImportedWorkflowObjectForBlock(jsonData: ExportImportWorkflow, workflow: WorkFlow, interactionPairs: Pair[],
    newInteractions: WorkflowInteraction[], subscription: BehaviorSubject<any>, userAction: string, elementPairs: Pair[],
    oldWorkflow?: WorkFlow, pairs?: Pair[], interactionPairIds?: any[], importedWorkflowName?: string): Promise<any> {
    try {
      if (userAction === 'create') {
        await this.updateImportedWorkflowObjectForBlockForCreateNewOption(workflow, interactionPairs, newInteractions, elementPairs);
      } else if (userAction === 'map') {
        await this.updateImportedWorkflowObjectForBlockForMapExistingOption(jsonData, newInteractions, oldWorkflow, pairs, elementPairs);
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }

    this.isWaiting = false;
    this.isWaitingChange.emit(this.isWaiting);
    this.waitMessage = '';
    this.waitMessageChange.emit(this.waitMessage);
    subscription.unsubscribe();

    this.workflowBuilderComponent.workflow = workflow;
    const dialogData = new GeneralDialogModel(
      'Workflow Activation Dialog',
      `Do you want to activate this version of workflow ? <br> :: ${this.workflowBuilderComponent.workflow.name} ::`,
      'Yes', 'No');
    // open dialog for workflow activation
    this.openGeneralDialog(dialogData, this.submitWorkFlowToBlock,
      { that: this.workflowBuilderComponent, isImportSubmit: true }, this.submitWorkFlowToBlock);

    // once workflow is saved, open IdMapIdChangesComponent dialog for changed id's
    this.workflowSubmittedToBlockSubscription.pipe(take(1)).subscribe(importedWorkflowTimestamp => {
      if (importedWorkflowTimestamp) {
        const changesDialogData: FormViewerModel = new FormViewerModel(
          undefined,
          undefined,
          'Ok', 'Cancel',
          {
            entity: userAction === 'create' ? workflow : jsonData['workflowMapperObject'],
            type: 'Workflow',
            idList: this.idList,
            environment: jsonData.environment,
            oldEntityVersion: jsonData.workflowVersion,
            newEntityVersion: importedWorkflowTimestamp,
            oldGroupName: this.importedWorkflowGroupName,
            interactionPairIds: interactionPairIds,
            importedEntityName: importedWorkflowName
          }
        );
        this.dialog.open(IdMapIdChangesComponent, {
          data: changesDialogData,
          disableClose: true,
          minWidth: '975px'
        });
      }
    });
  }

  /**
   * Followup for Ben.
   */
  captureDescriptionOfWorkflow(): void {
    if (this.workflow.descriptions && this.workflow.descriptions.length > 0) {
      const workflowId = this.workflow.id;
      const workflowVersion = this.workflow.timestamp;
      const workflowPublicKey = this.workflow.groupPublicKey;
      // || this is used to update the natural language descriptions.
      const isActive = true;
      if (isActive) {
        // then update natural language functions.
      }
    }
  }

  /**
   * This function updates the id's of newly imported interaction for new environment for create new workflow option
   *
   * @param workflow Imported workflow object being updated
   * @param interactionPairs Pairs of matched interactions
   * @param newInteractions New Interactions being imported
   * @param elementPairs Pairs of matched form elements
   */
  private async updateImportedWorkflowObjectForBlockForCreateNewOption(workflow: WorkFlow, interactionPairs: Pair[],
    newInteractions: WorkflowInteraction[], elementPairs: Pair[]): Promise<any> {
    this.isWaiting = true;
    this.isWaitingChange.emit(this.isWaiting);
    this.waitMessage = 'Updating workflow id\'s';
    this.waitMessageChange.emit(this.waitMessage);

    // update newInteractions array with workflowInteraction object references
    newInteractions = newInteractions.map(interaction => {
      const workflowInteraction = workflow.interactions.find(interactionObject =>
        interactionObject.interactionId === interaction.interactionId);
      interaction = workflowInteraction;
      return workflowInteraction;
    });
    try {
      // change all the id's in the imported workflow
      await this.changeIds(workflow, this.newWorkflowId, interactionPairs, elementPairs);
      const dbWorkflowUpdateResult = await this.idMapperService.sendWorkflowIds();
      const dbInteractionUpdateResult = await this.idMapperService.sendInteractionIds();
      const dbElementUpdateResult = await this.idMapperService.sendElementIds();
      if (!dbWorkflowUpdateResult || !dbInteractionUpdateResult || !dbElementUpdateResult) {
        this.loggingService.logMessage('Not all ids added', false, 'error');
        return Promise.reject('Not all ids added');
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }

    // update form elements' name property with new id's and change condition id's
    workflow.interactions.forEach(interaction => {
      interaction.interactionId = interaction.interaction.id;
      interaction.interaction['FormScreens'].forEach(formScreen => {
        formScreen['FormElements'].forEach(formElement => {
          formElement.name = formElement.id;
        });
      });
      interaction.conditions.forEach(interactionCondition => {
        if (interactionCondition.condition && Array.isArray(interactionCondition.condition)) {
          interactionCondition.condition.forEach(condition => {
            condition.conditions.forEach(elementCondition => {
              const index = this.idList.findIndex(idPair => idPair.oldId === elementCondition.elementOriginalId);
              if (index !== -1) {
                elementCondition.elementOriginalId = this.idList[index].newId;
              }
            });
          });
        }
      });
    });
    try {
      // create new version of matched interactions with same id's
      await this.updateInteractionPairsAfterNewIds(interactionPairs, workflow, true, elementPairs);
      await this.updateNewInteractionsAfterNewIds(newInteractions, workflow, true);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  /**
   * This function updates the id's of newly imported interaction for new environment for map existing workflow option
   *
   * @param jsonData Workflow data from imported .json file
   * @param newInteractions New Interactions being imported
   * @param oldWorkflow Existing workflow being updated
   * @param pairs Pair of matched interactions
   * @param elementPairs Pairs of matched form elements
   */
  private async updateImportedWorkflowObjectForBlockForMapExistingOption(jsonData: ExportImportWorkflow,
    newInteractions: WorkflowInteraction[], oldWorkflow: WorkFlow, pairs: Pair[], elementPairs: Pair[]): Promise<any> {
    this.isWaiting = true;
    this.isWaitingChange.emit(this.isWaiting);
    this.waitMessage = 'Updating workflow id\'s';
    this.waitMessageChange.emit(this.waitMessage);

    try {
      // change all the id's in the imported workflow
      await this.changeIds(jsonData['workflowMapperObject'], oldWorkflow.id, pairs, elementPairs, jsonData.environment);
      const dbWorkflowUpdateResult = await this.idMapperService.sendWorkflowIds();
      const dbInteractionUpdateResult = await this.idMapperService.sendInteractionIds();
      const dbElementUpdateResult = await this.idMapperService.sendElementIds();
      if (!dbWorkflowUpdateResult || !dbInteractionUpdateResult || !dbElementUpdateResult) {
        this.loggingService.logMessage('Not all ids added', false, 'error');
        return Promise.reject('Not all ids added');
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }

    // update updated imported workflow id with existing workflow id
    jsonData['workflowMapperObject'].id = oldWorkflow.id;
    // update form elements' name property with new id's and change condition id's
    jsonData['workflowMapperObject'].interactions.forEach(interaction => {
      interaction.interactionId = interaction.interaction.id;
      interaction.interaction['FormScreens'].forEach(formScreen => {
        formScreen['FormElements'].forEach(formElement => {
          formElement.name = formElement.id;
        });
      });
      interaction.conditions.forEach(interactionCondition => {
        if (interactionCondition.condition && Array.isArray(interactionCondition.condition)) {
          interactionCondition.condition.forEach(condition => {
            condition.conditions.forEach(elementCondition => {
              const index = this.idList.findIndex(idPair => idPair.oldId === elementCondition.elementOriginalId);
              if (index !== -1) {
                elementCondition.elementOriginalId = this.idList[index].newId;
              }
            });
          });
        }
      });
    });

    try {
      // create new version of matched interactions with same id's
      await this.updateInteractionPairsAfterNewIds(pairs, jsonData['workflowMapperObject'], true, elementPairs);
      await this.updateNewInteractionsAfterNewIds(newInteractions, jsonData['workflowMapperObject'], true);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }

    const workflowKeys = Object.keys(oldWorkflow);
    workflowKeys.forEach(key => {
      const value = oldWorkflow[key];
      if (typeof value !== 'object' && key !== 'id') {
        jsonData['workflowMapperObject'][key] = value;
      }
    });
  }

  /**
   * This function opens dialog box of type IdMapDialogComponent
   *
   * @param jsonData Workflow data from imported .json file
   */
  openImportActionDialog(jsonData: ExportImportWorkflow): void {
    jsonData.workflowMapperObject.interactions.forEach(interaction => {
      interaction.interaction = this.formBuilderService.fixOldShapeForRelations(interaction.interaction);
    });

    // get all interactions by user groups
    this.getInteractionsByUserGroups({that: this.workflowBuilderComponent});
    this.importedWorkflowGroupName = jsonData.groupName;

    const idMapDialogReference = this.dialog.open(IdMapDialogComponent);
    idMapDialogReference.afterClosed().subscribe((userAction: string) => {
      // if create new workflow option selected
      if (userAction === 'create') {
        this.openCreateNewWorkflowDialog(jsonData, userAction);
      } else if (userAction === 'map') {
        // if map existing workflow option is selected
        this.openMapExistingWorkflowDialog(jsonData, userAction);
      }
    });
  }

  /**
   * This function is called when user chooses to map an existing workflow from imported one
   *
   * @param jsonData Workflow data from imported .json file
   * @param userAction Action chosen by the user - 'create' or 'map'
   */
  openMapExistingWorkflowDialog(jsonData: ExportImportWorkflow, userAction: string): void {
    this.openIdMapWorkflowSelectDialog(jsonData, userAction);
  }

  /**
   * This function opens dialog box of type EntityPickerDialogComponent
   *
   * @param jsonData Workflow data from imported .json file
   * @param userAction Action chosen by the user - 'create' or 'map'
   */
  openIdMapWorkflowSelectDialog(jsonData: ExportImportWorkflow, userAction: string): void {
    const dialogData = new GeneralDialogModel(
      'Select a Workflow',
      `You may choose a group then an workflow and next its version to pick the workflow`,
      'Submit', 'Cancel', null,
      {
        "userGroups": JSON.parse(JSON.stringify(this.evaGlobalService.userGroups)),
        "entityType": entityType.workflow,
        "pickType": entityPickType.all,
        "fromImport": true
      }
    );
    const callbackData = { data: dialogData, disableClose: true };
    const idMapWorkflowSelectReference = this.dialog.open(EntityPickerDialogComponent, callbackData);
    this.generalDialogChangeSubscription = this.generalDialogService.generalDialogChanged$.subscribe(
      changeObj => {
        if (changeObj) {
          callbackData['generalDialogOnChange'] = changeObj;
        }
      },
      err => { console.log(err); }
    );
    idMapWorkflowSelectReference.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.openIdMapWorkflowInteractionDialog(jsonData, userAction, callbackData);
      }
    });
  }

  /**
   * This function opens dialog box of type IdMapWorkflowInteractionElementDialogComponent
   *
   * @param jsonData Workflow data from imported .json file
   * @param workflow Imported workflow object
   * @param interactionPairs Pairs of matched interactions
   * @param newInteractions New Interactions being imported
   * @param subscription subscription for announcing once interaction pairs are ready
   * @param userAction Action chosen by the user - 'create' or 'map'
   * @param oldWorkflow Workflow object selected by user for mapping with imported workflow
   * @param dialogResult Result data from IdMapWorkflowInteractionDialogComponent dialog
   * @param pairs Pairs of mapped workflow interactions for map existing workflow option
   * @param dialogResultData Result data from IdMapWorkflowInteractionMapDialogComponent dialog
   * @param groupPairs Pairs of mapped group destinations
   */
  openIdMapWorkflowInteractionElementDialog(jsonData: ExportImportWorkflow, workflow: WorkFlow, interactionPairs: Pair[],
    newInteractions: WorkflowInteraction[], subscription: BehaviorSubject<any>, userAction: string, existingMappings: any,
    oldWorkflow?: WorkFlow, pairs?: Pair[], dialogResultData?: IdMapWorkflowInteractionMapDialogResult, groupPairs?: Pair[],
    importedWorkflowName?: string): void {
    this.isWaiting = false;
    this.isWaitingChange.emit(this.isWaiting);
    this.waitMessage = '';
    this.waitMessageChange.emit(this.waitMessage);
    if ((userAction === 'create' && interactionPairs.length > 0) || (userAction === 'map' && pairs.length > 0)) {
      const mapDialogData: FormViewerModel = new FormViewerModel(
        undefined,
        undefined,
        'Ok', 'Cancel', {
          interactionPairs: userAction === 'create' ? interactionPairs : pairs,
          existingMappings: existingMappings,
          environment: jsonData.environment
        });
      const idMapInteractionElementRef = this.dialog.open(IdMapWorkflowInteractionElementDialogComponent, {
        data: mapDialogData,
        disableClose: true
      });
      idMapInteractionElementRef.afterClosed().subscribe(async (elementPairs: Pair[]) => {
        if (elementPairs) {
          try {
            await this.updateImportedWorkflowObjectAfterElementPairing(jsonData, workflow, interactionPairs, newInteractions,
              subscription, userAction, elementPairs, oldWorkflow, pairs, dialogResultData, groupPairs, importedWorkflowName);
          } catch (error) {
            console.error(error);
            return Promise.reject(error);
          }
        }
      });
    } else {
      this.updateImportedWorkflowObjectAfterElementPairing(jsonData, workflow, interactionPairs, newInteractions,
        subscription, userAction, [], oldWorkflow, pairs, dialogResultData, groupPairs, importedWorkflowName);
    }
  }

  /**
   * This function opens IdMapInteractionChangesComponent after mapping form elements for workflow interactions
   *
   * @param jsonData Workflow data from imported .json file
   * @param workflow Imported workflow object
   * @param interactionPairs Pairs of matched interactions
   * @param newInteractions New Interactions being imported
   * @param subscription subscription for announcing once interaction pairs are ready
   * @param userAction Action chosen by the user - 'create' or 'map'
   * @param elementPairs Pairs of mapped form elements
   * @param oldWorkflow Workflow object selected by user for mapping with imported workflow
   * @param pairs Pairs of mapped workflow interactions for map existing workflow option
   * @param dialogResultData Result data from IdMapWorkflowInteractionMapDialogComponent dialog
   * @param groupPairs Pairs of mapped group destinations
   */
  updateImportedWorkflowObjectAfterElementPairing = async (jsonData: ExportImportWorkflow, workflow: WorkFlow, interactionPairs: Pair[],
    newInteractions: WorkflowInteraction[], subscription: BehaviorSubject<any>, userAction: string, elementPairs: Pair[],
    oldWorkflow?: WorkFlow, pairs?: Pair[], dialogResultData?: IdMapWorkflowInteractionMapDialogResult, groupPairs?: Pair[],
    importedWorkflowName?: string): Promise<void> => {
    workflow.interactions.forEach(interaction => {
      if (Array.isArray(this.interactionsByGroups[interaction.interactionGroupPublicKey])) {
        this.interactionsByGroups[interaction.interactionGroupPublicKey].push(interaction);
      } else {
        this.interactionsByGroups[interaction.interactionGroupPublicKey] = [interaction];
      }
    });
    jsonData['workflowMapperObject'].interactions.forEach(interaction => {
      if (Array.isArray(this.interactionsByGroups[interaction.interactionGroupPublicKey])) {
        this.interactionsByGroups[interaction.interactionGroupPublicKey].push(interaction);
      } else {
        this.interactionsByGroups[interaction.interactionGroupPublicKey] = [interaction];
      }
    });
    if (userAction === 'create') {
      // if user selects create new workflow option, open IdMapInteractionChangesComponent to show workflow changes
      const changesDialogData: FormViewerModel = new FormViewerModel(undefined, undefined, 'Ok', 'Cancel',
        {
          entityType: 'workflow',
          newWorkflow: workflow,
          oldWorkflow: jsonData['workflowMapperObject'],
          interactionsByGroups: this.interactionsByGroups
        });
      const idMapInteractionChangesReference = this.dialog.open(IdMapInteractionChangesComponent, {
        data: changesDialogData,
        disableClose: true
      });
      idMapInteractionChangesReference.afterClosed().subscribe(async res => {
        if (res) {
          try {
            await this.updateImportedWorkflowObjectForBlock(jsonData, workflow, interactionPairs,
              newInteractions, subscription, userAction, elementPairs);
          } catch (error) {
            console.error(error);
          }
        }
      });
    } else if (userAction === 'map') {
      // if user selects map existing workflow option, open IdMapInteractionChangesComponent to show workflow changes
      this.updateImportedWorkflowObject(jsonData['workflowMapperObject'], dialogResultData, groupPairs);
      const interactionPairIds = pairs.map(pair => {
        return {
          newId: pair.new.interactionId,
          oldId: pair.old.interactionId
        };
      });
      try {
        // update imported workflow object with mapped workflow data
        await this.updateInteractionPairsAfterNewIds(pairs, jsonData['workflowMapperObject'], false, elementPairs);
        await this.updateNewInteractionsAfterNewIds(newInteractions, jsonData['workflowMapperObject'], false);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
      const changesDialogData: FormViewerModel = new FormViewerModel(
        undefined,
        undefined,
        'Ok', 'Cancel',
        {
          entityType: 'workflow',
          oldWorkflow: oldWorkflow,
          newWorkflow: jsonData['workflowMapperObject'],
          interactionsByGroups: this.interactionsByGroups
        });
      const idMapInteractionChangesReference = this.dialog.open(IdMapInteractionChangesComponent, {
        data: changesDialogData,
        disableClose: true
      });
      idMapInteractionChangesReference.afterClosed().subscribe(async afterChangeResult => {
        if (afterChangeResult) {
          try {
            await this.updateImportedWorkflowObjectForBlock(jsonData, workflow, interactionPairs, newInteractions,
              subscription, userAction, elementPairs, oldWorkflow, pairs, interactionPairIds, importedWorkflowName);
          } catch (error) {
            console.error(error);
          }
        }
      });
    }
  }

  /**
   * This function submits the form with updates to the Blockchain.
   * Tries to submit the form multiple times if unsuccessful before failing completely.
   *
   * @param workflowInteraction Workflow Interaction being submitted to the block
   */
  async submitFormToBlock(workflowInteraction: WorkflowInteraction): Promise<any> {
    const dynamicForm = JSON.parse(JSON.stringify(workflowInteraction.interaction));
    dynamicForm['FormScreens'].forEach((screen: any, index: number) => {
      screen['order'] = index;
    });

    try {
      await this.signingService.signAndSendObject(dynamicForm, ProjectSettings.types.dynamicInteractions.typeId);
      return;
    } catch (err) {
      console.error(err);
    }

    try {
      await this.signingService.signAndSendObject(dynamicForm, ProjectSettings.types.dynamicInteractions.typeId);
      return;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  /**
   * This function calls the recursive function to update ids of the imported workflow object
   *
   * @param workflow Imported workflow object being updated
   * @param existingWorkflowID Id of the existing workflow being mapped, if creating new, use same id as workflow.id
   * @param interactionPairs Pairs of matched interactions
   * @param elementPairs Pairs of mapped form elements
   */
  async changeIds(workflow: WorkFlow, existingWorkflowID: string, interactionPairs: Pair[], elementPairs: Pair[],
    importedWorkflowEnvironment?: string): Promise<any> {
    try {
      await this.searchObj(workflow, 'id', workflow.id, existingWorkflowID, interactionPairs,
        elementPairs, workflow, importedWorkflowEnvironment);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  /**
   * This is a recursive function that updates the ids of the imported workflow object
   *
   * @param object imported workflow object being updated
   * @param query property of object being updated
   * @param workflowID Id of the imported workflow object from old environment
   * @param existingWorkflowID Id of the existing workflow being mapped, if creating new, use same id as workflow.id
   * @param interactionPairs Pairs of matched interactions
   * @param elementPairs Pairs of mapped form elements
   * @param workflowMapperObject Imported workflow object
   */
  async searchObj(object: any, query: string, workflowID: string, existingWorkflowID: string,
    interactionPairs: Pair[], elementPairs: Pair[], workflowMapperObject: WorkFlow, importedWorkflowEnvironment?: string): Promise<any> {
    try {
      for (const key in object) {
        const value = object[key];
        let pairType = '';  // type of the paired entity - 'interaction' or 'form element'

        // if property is an object, recurse through it
        if (typeof value === 'object') {
          await this.searchObj(value, query, workflowID, existingWorkflowID, interactionPairs, elementPairs,
            workflowMapperObject, importedWorkflowEnvironment);
        }

        // remove temporary now unnecessary properties
        if (key === 'selectedAction' || key === 'selected' || key === 'groupChanged'
          || key === 'stepperIndex' || key === 'selectedOldScreen' || key === 'selectedNewScreen' || key === 'marked') {
          delete object[key];
        }

        // update workflow.interactionId with workflow.interaction.id
        if (key === 'interactionId') {
          object[query] = object[key];
        }

        // if key is query and value is a valid GUID, update the value
        if (key === query && Guid.isValid(value) || (key === 'value' && Guid.isValid(value))) {
          const compareValue = Array.isArray(value) ? value[0] : value;
          const objectWithId: IdMapPair = {
            oldId: compareValue,
            newId: null,
            docId: workflowID + '_' + existingWorkflowID
          };
          // check if id exists as a pair in paired interactions or elements
          // if so, use the mapped id else check if the id exists in the database otherwise use new id
          let pair = interactionPairs.find(interactionPair => interactionPair.new.interactionId === compareValue);
          if (!pair) {
            pair = elementPairs.find(elementPair => elementPair.new.id === compareValue);
            if (pair) {
              pairType = 'element';
            }
          } else {
            pairType = 'interaction';
          }
          // if user pair exists, check the pair entity type and store those respective mappings
          if (pair) {
            if (pairType === 'interaction') {
              objectWithId.newId = pair.old.interactionId;
              if ((pair.old.interactionId === pair.new.interactionId && this.getCurrentEnvironment() === importedWorkflowEnvironment)
                || pair.old.interactionId !== pair.new.interactionId) {
                await this.idMapperService.mapInteractionId(compareValue, workflowID, existingWorkflowID, pair.old.interactionId);
              }
            } else if (pairType === 'element') {
              objectWithId.newId = pair.old.id;
              await this.idMapperService.mapElementId(compareValue, workflowID, existingWorkflowID, pair.old.id);
            }
          } else {
            if (workflowID === compareValue) {
              // if value is workflow id, assign it existingWorkflowId
              objectWithId.newId = await this.idMapperService.mapWorkflowId(compareValue, workflowID, existingWorkflowID,
                existingWorkflowID);
            } else if (this.idList.find(idPair => idPair.oldId === compareValue)) {
              // if id already exists in the already paired id's list, use that mapped id
              const idListPair = this.idList.find(idPair => idPair.oldId === compareValue);
              if (idListPair) {
                objectWithId.newId = idListPair.newId;
              }
            } else if (workflowMapperObject.interactions.find(interaction =>
              interaction.interactionId === compareValue || interaction.interaction.id === compareValue
                || interaction.conditions.find(condition =>
                  condition.trueDestination.find(destination => destination.id === compareValue && destination.type === 'interaction')
                  || condition.falseDestination.find(destination => destination.id === compareValue && destination.type === 'interaction'))
                ? true : false)) {
              // else if id is of an interaction, check the interaction collection
              objectWithId.newId = await this.idMapperService.mapInteractionId(compareValue, workflowID, existingWorkflowID, null, true);
            } else if (workflowMapperObject.interactions.find(interaction =>
              interaction.conditions.find(condition => condition.id === compareValue
                || condition.trueDestination.find(destination => destination.id === compareValue && destination.type === 'condition')
                || condition.falseDestination.find(destination => destination.id === compareValue && destination.type === 'condition'))
                ? true : false
            )) {
              // else if id is of a condition, check the element collection
              objectWithId.newId = await this.idMapperService.mapElementId(compareValue, workflowID, existingWorkflowID, null, true);
            } else if (workflowMapperObject.interactions.find(interaction =>
              interaction.interaction.specialControls.find((specialControl: any) => specialControl.id === compareValue))) {
              // else if id is of a special control object, check the element collection
              objectWithId.newId = await this.idMapperService.mapElementId(compareValue, workflowID, existingWorkflowID, null, true);
            } else if (workflowMapperObject.interactions.find(interaction =>
              interaction.interaction.FormScreens.find((formScreen: any) =>
                formScreen.FormElements.find((formElement: any) =>
                  formElement.id === compareValue
                )
              ) || interaction.interaction.specialControls.find((specialControl: any) =>
                specialControl.controls.find((control: any) =>
                  control.id === compareValue)
              )
            )) {
              // else if id is of a form element, check the element collection
              objectWithId.newId = await this.idMapperService.mapElementId(compareValue, workflowID, existingWorkflowID, null, true);
            }
          }
          // update id map pair old id with the imported entity id from previous environment based on type of entity
          if (pairType === 'interaction') {
            objectWithId['oldId'] = pair.new.interactionId;
          } else if (pairType === 'element') {
            objectWithId['oldId'] = pair.new.id;
          }
          if (!this.idList.find(idPair => idPair.newId === compareValue)) {
            object[key] = Array.isArray(value) ? [objectWithId.newId] : objectWithId.newId;
          }
          // update form element name property if current object is a form element
          if (object['name'] === compareValue) {
            object['name'] = objectWithId.newId;
          }
          // add mapped pair to the list
          this.idList.push(objectWithId);
        }
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  /**
   * This function returns the string equivalent of the current environment based on window url
   */
  getCurrentEnvironment(): string {
    const url = window.location.href;
    let environmentName = '';

    // set the environment based on url
    if (url.includes('dev.atbeva.com') || url.includes('localhost')) {
      environmentName = 'Development';
    } else if (url.includes('staging.atbeva.com')) {
      environmentName = 'Staging';
    } else if (url.includes('test.atbeva.com')) {
      environmentName = 'Test';
    } else if (url.includes('atbeva.com')) {
      environmentName = 'Production';
    }

    return environmentName;
  }
}
